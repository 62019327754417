  /* .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; 
  }
  
  .video-container iframe {
    position: fixed;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
  } */

  .cards-div{
    display: flex;
    margin: 20px;
    justify-content: right;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay-content {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .moreSettings-mainStyle{
    padding:20px;
  }


  
  .loading-container {
    text-align: center;
  }
  
  .loading-circle {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto; /* Center the circle */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  .ad-image img{
    width: -webkit-fill-available;
    max-width: 90%;
    height: auto;
    border-radius: 15px;
    max-height: 130px;
    margin:35px 20px 0 20px; 
    animation: blink 1.3s infinite alternate;
    box-shadow: 12px 12px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    /* transition: all 0.3s ease-in-out; */
   }

   /* .ad-image img:hover {
    transform: scale(1.1);
  }
  
  .ad-image img:active {
    transform: scale(1.1); 
  } */
   @keyframes blink {
    from {
      box-shadow: 12px 12px 8px rgba(0, 0, 0, 0.3);
    }
    to {
      box-shadow: 20px 40px 33px rgba(0, 0, 0, 0.5);
    }
  }
  
  @media screen and (max-width: 600px) {
    .cards-div {
      margin: 0;
    }
  }