.sidebar {
    width: 0;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    color: rgb(46, 46, 46);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 4vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .sidebar.open {
    width: 70vw;
    max-width: 350px;
  }
  
 
  .sidebar-list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #1c3664;
    
  }

  .sideBar-list .list-styling li{
  padding: 2vh 0px;
  font-size: 1rem ;
  font-weight: 400;
  color: #1c3664;
  }

  .sideBar-list .list-styling li:hover{
    font-size: 1.1rem; 
    background-color: aliceblue;
    padding: 10px;
    border-radius: 1rem;
  }
  
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .overlay.open {
    display: block;
  }

  .roaya-name{
    font-size: 1.4rem;
    font-weight: 500;
    color: #1c3664;
    padding-bottom: 15px;

  }
  
  .separator {
    border: 1px solid #dddddd; 
    margin: 0 10px; 
    width: 150px;
  }
  