/* AboutUs.css */

.about-us-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    padding: 2rem;
    scrollbar-width: none;
  }
  
  .about-us-overlay::-webkit-scrollbar {
    width: 0;
  }
  
  .about-us-content {
    overflow-x: auto;
    height: 90%;
    background: #fff;
    color: #606060;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .about-us-container {
    text-align: center;

  }
  
  .about-us-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .about-us-text {
    line-height: 1.8;
    text-align: right;
  }
  
  .about-us-close-button {
    margin-top: 1rem;
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .about-us-close-button:hover {
    background-color: #0056b3;
  }
  