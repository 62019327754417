body {
    padding-top: 12vh;
    font-family: 'Readex Pro', sans-serif;
    background-color: #f3f3f3;
}

::-webkit-scrollbar {
    width: 7px;
    height: 3px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #ff8025;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #ffcb93;
  }