.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}


.post-list-container {
  overflow-x: auto;
}

.h2{
  display:flex ;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.7rem;
  padding: 10px ;
  color: #1c3664;
}


.scroll-container {
  overflow-y: hidden;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-bottom: 10px;
    overflow-x: auto;
    white-space: nowrap; /* Ensure the children are in a single line */
}

.scroll-container::-webkit-scrollbar {
  width: 0;
}

.scroll-container {
  scrollbar-width: none;
}
.scroll-content {
    display: flex;
    flex-direction: row-reverse;
}




.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}


.post-list-container {
  overflow-x: auto;
}

.h2{
  display:flex ;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.7rem;
  padding: 10px ;
}


/* .scroll-container {
  overflow-y: hidden;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-bottom: 10px;
    overflow-x: auto;
    white-space: nowrap; 
}

.scroll-container::-webkit-scrollbar {
  width: 0;
}


.scroll-container {
  overflow: auto;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-bottom: 10px;
  position: relative;
  white-space: nowrap; 
}

.scroll-container::-webkit-scrollbar {
  width: 0;
}

.scroll-container {
  scrollbar-width: none;
}

.scroll-content {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}



.arrow {
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;;
  width:50px;
  height: 50px;
  border-radius: 50%;
  padding:20px;
  font-weight: 500;
  font-size: 3rem;
  color: #ff8025;
cursor: pointer;
}

.arrow.left {
  left: 10px;
 }

 .arrow.right {
  right: 10px;
 }
.arrow:hover {
font-size: 4rem;
transition: ease-in-out 0.3s;
}

.post-list-LRbuttons{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1vw;
} */



  .swiper-button-prev, .swiper-button-next{
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  background-color: #ff8025c6;
  color: white;
  font-weight: 900;
}


:root {
  --swiper-theme-color: #ffffff !important;
  --swiper-navigation-size:20px !important;
}