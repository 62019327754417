
.main-section {
    padding: 0.7rem;
    margin: 0.7rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.4rem;
    overflow: hidden;
    flex-direction: row-reverse;
  }
  
  .right-section {
    flex: 1;
    overflow-x: auto;
    display: contents;
    flex-wrap: wrap;
    gap: 0.7rem;
    white-space: wrap;
    align-items: center;
    justify-content: center;
    
  }
  .right-section .post-content,  .right-section .post-title, .right-section .post-meta {
    background: linear-gradient(45deg, #df1717, #ad0000);
    color: white ;
    line-height: 1.8;
    border-color: #ad0000;
    border-radius: 0 0 8px 8px;
    font-size: larger;
    padding: 5px;
  }

  .right-section  img{
    height: 96vh;
  }
  
  .left-section {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 0.7rem;
    justify-content: space-between;
  }
  
  .post-view {
    flex-basis: calc(50% - 0.7rem);
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }

  .post-view:hover {
    transform: scale(1.02);
  }


  /* .post-view {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  .post-view:not(.active) {
    opacity: 0;
  } */


  .left-section .post-image {
    height:37vh;
    object-fit: cover;
  }
  

  
  .left-section .post-title {
    font-size: 18px;
    padding: 0.1rem;
    text-align: right;
    font-weight: 500;
    color: #4a4a4a;
    line-height: 1.6;
  } 
  
  .post-view hr {
    margin: 10px;
  }
  
  @media (max-width: 1000px) {
    .main-section {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .right-section  img{
      height: 40vh;
    }


    .custom-video-section iframe{
      height: 50vh !important;
      margin-bottom: 4vh;
    }
  
    .left-section,
    .right-section,
    .custom-video-section {
      flex: 1;
      justify-content: center;
    }
  
    .post-view {
      flex-basis: calc(100% - 0.7rem);
      margin-bottom:20px;
      max-width: 600px;
    }
  }

  .custom-video-section iframe{
   display: flex;
   flex: 1;
   width: -webkit-fill-available;
   height: 100%;
   border-radius:8px;
  }