.post-card {
  
    width: 25vw;
    max-width: 300px;
    min-width: 250px;
    height: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    
  }
  
  .post-card:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  .post-image {
    width: 100%; 
    height: 28vh;
    /* margin-bottom: 10px; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

  }

  .post-header {
    margin: 7px;
  }
  
  .post-title {
    /* font-size: 1.1rem;
    margin: 0;
    font-weight: 500;
    text-align: right;
    color: #333; */
    text-wrap: wrap;
    line-height: 1.8;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
        text-align: right;

  }
  
  .post-meta {
    color: #555;
    margin: 4px;
  }
  
  .post-content {
    color: #333;
    line-height: 1.4;
    text-align: right;
    padding: 5px;
  }


  .fullScreen-content{
    width: 100%;
    padding: 8px;
    border: 1px solid #fff8f0;
    border-radius: 4px;
    background-color: aliceblue;

  }

.img-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
}
.img-container img{
  border-radius: 30px;
}

.fs-Title{
  text-align: right;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.9;
  color: black;
}

.fs-content{
font-size: 1.6rem;
text-align: right;
line-height: 1.9;
font-weight: 400;
color: #4a4a4a;
}

@media screen and (max-width: 800px) {
  .fs-Title {
    font-size: 1.4rem; 
  }

  .fs-content {
    font-size: 1.2rem;
  }
  .post-title {
    /* font-size: 16px; */
  }
}

.full-post-container{
  display: flex;
  flex-direction: column;
  gap: 3vh;
  padding: 3vw;
}
/* 
.images-fs-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.images-fs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.img-container {
  width: 50%; 
  box-sizing: border-box;
  padding: 5px;

@media (max-width: 768px) {
  .img-container {
    width: 100%; 
} */


.post-card {
  position: relative;
}

.thumbnail-container {
  position: relative;
}

.overlay-image {
  position: absolute;
  top: 40%;
  left: 35%;
  width: 30%;
  height: auto;
  object-fit: cover;
  z-index: 1;
  opacity: 0.9;
}

.date-category{
display: flex;
flex-direction: row;
justify-content: space-between;
padding: 7px;
}
.category-card{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #ff6b00;
  color: white;
  font-size: small;
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  .post-card{
    left: 53px;
  }
}
/* .ltr-text>* {
  direction: ltr;
  text-align: left;
}

.rtl-text>* {
  direction: rtl;
  text-align: right;
} */
