.footer {
  background: linear-gradient(45deg, #db7100, #ff8025);
  color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: -webkit-fill-available;
    row-gap: 10px;
  }
   .footer-content{
    width: 300px;
    display: flex;
    justify-content: space-between;   }

  .social-media-icons{
    display: flex;
    gap: 15px;
  }

  .social-media-icons a {
    color: #fff;
    margin-right: 20px;
    font-size: 30px;
    transition: color 0.3s ease-in-out;
  }
  
  .social-media-icons a:hover {
    color: #55acee;
  }
  
  .footer-links  {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    font-weight: 500;
    font-family:Arial, Helvetica, sans-serif;
    margin: 0 15px 0 15px;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .footer-links:hover {
    color: #000000;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.5s ease-in-out;
  }
  
  .copyright {
    font-size: 1.1rem;
    text-align: center;
  }
  