.navbar {
  height: 10vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  column-gap:4vw;
  align-items: center;
  background-color: #ffffff;
  color: rgb(47, 47, 47);
  padding: 0 2% 0 2% ; 
  z-index:22;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.logo img {
  width: 4rem;
  max-width: 80%;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.nav-item {
  cursor: pointer;
  transition: color 0.3s ease;
}

.menu-icon {
  cursor: pointer;
  color: rgb(47, 47, 47);
  margin-right: 10px;
  position: fixed;
  left: 10px; 
}


.searchBar-div {
  display: flex;
  justify-content: flex-end;
  width: 40vw;
}

@media (max-width: 610px) {
  .nav-list {
    flex-direction: column;
    gap: 0.5rem;
  }

  .nav-item {
    margin: 0;
  }

  .searchBar-div {
    width: 60%;
  }
}

.logout-btn{
  background-color: rgba(255, 217, 0, 0.461);
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
}
.roaya-letter-logo{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  font-size: 2rem;
  /* font-family: Arial, Helvetica, sans-serif;
  font-weight: 700; */
  font-style: italic;
  color: #1c3664;
  min-width: fit-content;
}

.wrap-ul .list-styling {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 0.6vw;
  justify-content: center;
}

.wrap-ul .list-styling li ,.back-button{
  margin: 0 5px 0 5px; 
  cursor: pointer;
  font-weight: 400;
  color: #1c3664;
  transition: font-size 0.3s;
  font-size: 1.2rem;

}

.wrap-ul .list-styling li:hover {
  font-size: 1.15rem; 
  background-color: aliceblue;
  border-radius: 1rem;
}


/* @keyframes fadeInOut {
  0% {
    opacity: 0.75;

  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.75;

  }
}

.fading-text {
  animation: fadeInOut 3s infinite; 
} */