/* .news-ticker {
  display: block;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #1c3664;
  margin: 2vw;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: inset 60px 0px 15px -15px rgba(216, 216, 216, 0.3);
}

.news-container {
  display: flex;
  height: 100%;
  animation: scrollNews linear infinite;
  animation-duration: 50s;
  animation-direction: reverse;
}


.news-item {
  padding: 0 ;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  padding-right: 45px;
}

@keyframes scrollNews {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.live-word {
  padding: 15px;
  color: #f0f0f0;
  background-color: #801a1a;
  z-index: 1;
}

.circle-img {
  border-radius: 50%;
  padding: 0 10px 0 10px;
  margin: 0 30px 0 15px;
} */

  
  /* @media (max-width: 768px) { */
    /* .news-ticker {
      flex-direction: column; 
      align-items: flex-start;
    }
  
    .news-container {
      flex-direction: column;
      gap: 10px;
      overflow: auto; 
    }
  
    .live-word {
      margin-top: 10px;
    } */
  
  



    .news-ticker {
  
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: #1c3664;
      padding: 5px;
      overflow: hidden;
      white-space: nowrap;
      box-shadow: inset 60px 0px 15px -15px rgba(216, 216, 216, 0.3);
      font-size: 1.2rem ;
    }
        
    
    .news-item {
      display: flex;
      align-items: center;
      padding: 0 20px; /* Adjust the padding as needed */
    }
     .news-item p{
      text-wrap: nowrap;
     }
    
    @keyframes scrollNews {
      from {
        transform: translateX(50%);
      }
      to {
        transform: translateX(-20%);
      }
    }
    
    
    .circle-img {
      border-radius: 50%;
      padding: 0 10px 0 10px;
      margin: 0 30px 0 15px;
    }
    
    
    .ticker-wrap {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      /* position: relative; */
      outline: none !important;

    }

    .ticker-label{
      position: absolute;
      margin-top: -4px;
      right: 0;
      z-index: 1;
      padding: 10px;
      color: white;
      background-image: linear-gradient(45deg, red, #b71414);
      margin-bottom: 0;
      border-radius: 5px;
    }

    
    .news-container {
      display: flex;
      flex-direction: row;
      height: 32px;
      line-height: 32px;
      white-space: nowrap;
      box-sizing: content-box;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 100s !important;
      /* animation-duration: 30s !important; */
      width: 100%;
      height: 40px;
      animation-play-state: running;
      /* animation-delay: 1.5s; */
      animation: scrollNews linear infinite;
      animation-direction: reverse;

    }

    @media (max-width: 900px) {
      .ticker-label.hidden-sm {
        display: none;
      }
    }
    


    