.roaya-team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .all-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    height: auto; 
  }
  
  .team-member {
    flex: 0 0 23%;
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .team-member img {
    width: 100%;
    max-width: fit-content;
    height: 300px;
    min-width: 280px;
     object-fit: cover; 
    border-radius: 8px;
  }
  
  .member-title {
    margin-top: 10px;
    font-weight: bold;
  }
  